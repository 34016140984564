<template>
  <div class="home">
    <v-btn @click="switchFlash()">Flash</v-btn>
    <v-btn @click="takeSnapshot()">Take photo</v-btn>
    <video muted autoplay id="vid"></video>
    <img>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  data: () => ({
    loading: true,
    flash: false,
  }),
  methods: {
    switchFlash() {
      this.flash = !this.flash;
    },
    takeSnapshot() {
      var img = document.querySelector('img') || document.createElement('img');
      var context;
      var width = vid.offsetWidth
        , height = vid.offsetHeight;

      var canvas = canvas || document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;

      context = canvas.getContext('2d');
      context.drawImage(vid, 0, 0, width, height);

      img.src = canvas.toDataURL('image/png');
      document.body.appendChild(img);
    }
  },
  mounted() {
    flashlightHandler.accessFlashlight();

    /*
    navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        facingMode: 'environment'
      }
    })
      .then(stream => vid.srcObject = stream)
      .catch(console.error);
    */

  },
  watch: {
    '$data.flash': {
      handler: function () {
        flashlightHandler.setFlashlightStatus(this.flash)
      },
      deep: true
    }
  }
}

class flashlightHandler {

  static track; //the video track which is used to turn on/off the flashlight

  static accessFlashlight() {
    //Test browser support
    if (!('mediaDevices' in window.navigator)) {
      alert("Media Devices not available. Use HTTPS!");
      return;
    };

    //Get the environment camera (usually the second one)
    window.navigator.mediaDevices.enumerateDevices().then((devices) => {

      const cameras = devices.filter((device) => device.kind === 'videoinput');
      if (cameras.length === 0) {
        alert("No camera found. If your device has camera available, check permissions.");
        return;
      };

      const camera = cameras[cameras.length - 1];

      window.navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: 'environment',
          deviceId: camera.deviceId
        }
      }).then((stream) => {
        this.track = stream.getVideoTracks()[0];
        vid.srcObject = stream;
        if (!(this.track.getCapabilities().torch)) {
          alert("No torch available.");
        };
      });
    });
  }

  static setFlashlightStatus(status) {
    this.track.applyConstraints({
      advanced: [{
        torch: status
      }]
    });
  }
}


</script>
